import React, { useCallback, useEffect, useState } from "react";
import "./footer.model.css";
import LogoImg from "../../assets/logo2.png";
import TwoCode from "../../assets/图片1.png";
// import LogoTwo from "../../assets/logo.png"
function FooterIndex() {
  const [size, setSize] = useState({
    width: document.documentElement.clientWidth,
    hieght: document.documentElement.clientHeight,
  });
  const onResize = useCallback(() => {
    setSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    });
  }, []);
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);
  const footerRight = () => {
    if (size.width <= 750) {
    } else {
      return (
        <div className="footer_top_right">
          <div className="right_item">
            <p className="title">关于百奥华兴</p>
            <p className="title_bro">
              <a
                target=""
                rel="noopener noreferrer"
                href="#/Biohuaxing/present"
              >
                简介
              </a>
            </p>
            <p className="title_bro">
              <a target="" rel="nooper noreferrer" href="">
                技术平台
              </a>
            </p>
            <p className="title_bro">
              <a target="" rel="nooper noreferrer" href="">
                研究成果
              </a>
            </p>
            <p className="title_bro">
              <a target="" rel="nooper noreferrer" href="">
                投资者关系
              </a>
            </p>
            <p className="title_bro">
              <a target="" rel="nooper noreferrer" href="">
                资质专利
              </a>
            </p>
            <p className="title_bro">
              <a target="" rel="nooper noreferrer" href="">
                新闻中心
              </a>
            </p>
            <p className="title_bro">
              <a target="" rel="nooper noreferrer" href="">
                联系我们
              </a>
            </p>
          </div>
          <div className="right_item">
            <p className="title">科技服务产品</p>
            <p className="title_bro">
              <a target="" rel="noopener noreferrer" href="#/Biohuaxing/Illm/">
                Illumina/T7 二代测序
              </a>
            </p>
            <p className="title_bro">
              <a
                target=""
                rel="noopener noreferrer"
                href="#/Biohuaxing/pacbio/"
              >
                PacBio/ONT 三代测序
              </a>
            </p>
            <p className="title_bro">
              <a
                target=""
                rel="noopener noreferrer"
                href="#/Biohuaxing/Genomics/"
              >
                基因组学分析及软件开发
              </a>
            </p>
            <p className="title_bro">
              <a target="" href="#/Biohuaxing/Pang/" rel="noopener noreferrer">
                泛基因组测序
              </a>
            </p>
            <p className="title_bro">
              <a
                target=""
                rel="noopener noreferrer"
                href="#/Biohuaxing/sequencing/"
              >
                单细胞测序分析及软件开发
              </a>
            </p>
            <p className="title_bro">
              <a
                target=""
                rel="noopener noreferrer"
                href="#/Biohuaxing/analysis/"
              >
                多组学大数据联合分析
              </a>
            </p>
            <p className="title_pro">
              <a
                target=""
                rel="noopener noreferrer"
                href="#/Biohuaxing/Functional/"
              >
                质谱分析
              </a>
            </p>
            <p className="title_bro">
              <a
                target=""
                rel="noopener noreferrer"
                href="#/Biohuaxing/Resources/"
              >
                种质资源评价
              </a>
            </p>
            <p className="title_bro">
              <a target="" rel="noopener noreferrer" href="#/Biohuaxing/Mole/">
                分子身份证开发
              </a>
            </p>
            <p className="title_bro">
              <a
                target=""
                rel="noopener noreferrer"
                href="#/Biohuaxing/animals/"
              >
                动植物全基因组重测序
              </a>
            </p>
          </div>
          <div className="right_item">
            <p className="title">生物育种大数据平台开发</p>
            <p className="title_bro">
              <a
                target=""
                rel="noopener noreferrer"
                href="#/Biohuaxing/Excavate/"
              >
                物种多组学大数据功能位点挖掘平台开发
              </a>
            </p>
            <p className="title_bro">
              <a
                target=""
                rel="noopener noreferrer"
                href="#/Biohuaxing/Intellgent/"
              >
                基因组选择及最优亲本选配智能平台开发
              </a>
            </p>
          </div>
          <div className="right_item">
            <p className="title">药物研发</p>
            <p className="title_bro">
              <a target="" rel="noopener noreferrer" href="">
                基因编辑工具开发及优化
              </a>
            </p>
            <p className="title_bro">
              <a
                target=""
                rel="noopener noreferrer"
                href="#/Biohuaxing/Detection/"
              >
                脱靶检测
              </a>
            </p>
            <p className="title_bro">
              <a target="" rel="noopener noreferrer" href="">
                工程菌改造
              </a>
            </p>
            <p className="title_bro">
              <a target="" rel="noopener noreferrer" href="">
                蛋白结构解析
              </a>
            </p>
          </div>
          <div className="right_item">
            <p className="title">生命科学基础设施平台</p>
            <p className="title_bro">
              <a
                target=""
                rel="noopener noreferrer"
                href="#/Biohuaxing/Variation/"
              >
                高通量测序变异检测软件算法和硬件加速（BWA、GATK等）
              </a>
            </p>
          </div>
        </div>
      );
    }
  };
  return (
    <div className="footer_div">
      <div className="footer_top">
        <div className="footer_top_child">
          <div
            className="footer_top_left"
            style={{
              width: size.width <= 750 ? "100%" : "",
              alignItems: size.width <= 750 ? "center" : "",
            }}
          >
            <img
              src={require("../../assets/logo2.png")}
              // height={20 + "%"}
              width={60 + "%"}
              alt=""
            />
            <div
              className="footer_left_bottom"
              style={{
                width: size.width <= 750 ? "100%" : "",
                alignItems: size.width <= 750 ? "center" : "",
              }}
            >
              <img
                src={require("../../assets/图片1.png")}
                width={35 + "%"}
                alt=""
              />

              <p
                style={{
                  width: "60%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  fontSize: "13px",
                }}
              >
                <span style={{ padding: "0.625rem 0" }}>电话:18322280330</span>
                <span>邮箱:support@biohuaxing.com</span>
              </p>
            </div>
          </div>
          {footerRight()}
        </div>
      </div>
      <div className="footer_bottom">
        <p>
        版权所有：北京百奥华兴基因科技有限公司
          <a
            href="https://beian.miit.gov.cn/"
            style={{ color: "black" ,margin:"0 5px"}}
            target="_blank"
          >
             京ICP备2024045847号
          </a>
        </p>
      </div>
    </div>
  );
}

export default FooterIndex;
